<template>
  <div>
    <el-card shadow="never" style="margin-top: 45px">
      <div slot="header" class="flex" style="align-items: center">
        <span>课程账号统计</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="dataList"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '课时统计'">
                <div class="slider-bar-box">
                  <el-row>
                    <el-col :span="10"
                      ><span class="slider-title">总课时</span></el-col
                    >
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.buyTotalAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.buyTotalAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: '#f5c319',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="10">
                      <span class="slider-title">
                        报名({{ cP(scope.row, "costAmount") }})
                      </span>
                    </el-col>
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.costAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.costAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: 'blue',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="10">
                      <span class="slider-title">
                        剩余({{ cP(scope.row, "remainAmount") }})
                      </span>
                    </el-col>
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.remainAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.remainAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: 'green',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="10"
                      ><span class="slider-title"
                        >课消({{ cP(scope.row, "usedAmount") }})</span
                      ></el-col
                    >
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.usedAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.usedAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: 'pink',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </div>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  type="text"
                  @click="gotoDetail(scope.row)"
                  >明细</el-button
                >
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
    </el-card>
    <change-pass :show="changePassDialog"  :showClose='showClose' :closeModal='closeModal' @close="changePassDialog=false" @savePass="savePass"></change-pass>
  </div>
</template>
<script>
import { getGoodsList } from "@/api/businesscourse/index";
import TextButton from "@/components/TextButton";
import {userEditPwd,merchantPasswordCheck} from '@/api/user'
import ChangePass from '../../public/user/ChangePass/index'
// 默认展示列

const baseColSetting = [
  {
    label: "课程类型",
    prop: "courseGoodsName",
    state: true,
    align: "left",
    // sortable: true,
    width: "100",
  },
  {
    label: "总数",
    prop: "buyTotalAmount",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "已使用",
    prop: "costAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "剩余",
    prop: "remainAmount",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { TextButton,ChangePass },
  data() {
    return {
      showClose:false,
      closeModal:true,
      changePassDialog:false,
      select:[],
      size: "default",
      tableKey: 1,
      dataList: [],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      changePass:false
    };
  },

  mounted() {
    let passSafe = localStorage.getItem('passSafe')
    if(passSafe === 'N') {
        this.changePassDialog = true
    }
    this.getList();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    // 保存密码
    async savePass (data) {
      let passData = {
        pwdOld: data.originalPass,
        pwdNew: data.newPass
      }
      // 密码校验
      const formdate = new FormData();
      formdate.append('password',  data.newPass,)
      const res = await merchantPasswordCheck(formdate)
      if(!res.body){
        window.$msg('当前新密码不符合密码规范，请重新填写密码', 2)
        return
      }
      await userEditPwd({ ...passData })
      this.$store.dispatch('user/loginOut').then(() => {
        window.$msg('密码修改成功,请重新登录', 2)
        this.close()
        this.changePassDialog = false
      })
    },
    gotoDetail (row) {
      this.$emit('getLog', row.courseGoodsId, row)
      this.$emit("setOptions", this.select);
    },
    getList() {
      getGoodsList().then((res) => {
        if (res.state == "success") {
          this.dataList = res.body.list;
          this.select = res.body.list.map((i) => ({
            value: i.courseGoodsId,
            label: i.courseGoodsName,
          }));
          this.$emit("setOptions", this.select);
        }
      });
    },
    /**
     * 查看
     */
    openDetailModal() {
      this.$message.warning("根据需求添加");
    },
    /**
     * 计算百分比 computed percent
     */
    cP(item, str) {
      return ((item[str] / item.buyTotalAmount) * 100).toFixed(0) + "%";
    },
    /**
     * 刷新
     */
    search() {
      // this.pageInfo.pageNum = 1;
      this.getList();
      // this.getGoodsList();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slider-title {
  line-height: 20px;
  font-size: 12px;
}
.slider-bar-box {
  text-align: left;
}
.slider-bar {
  line-height: 20px;
  display: inline-block;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #f5c319;
}
</style>
