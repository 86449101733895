import $axios from '@/utils/request'
/**
 * 查询商户购买的收费课程列表
 * @param {*} data 
 * @returns 
 */
export function getGoodsList(data) {
    const url = '/merchant/course/goods/list'
    return $axios.fGet(url, data)
}
/**
 * 查询商户收费课程明细列表
 * /cms/merchant/course/goods/log/list
 * @param {*} data 
 * @returns 
 */
export function getGoodsLogList(data) {
    const url = '/merchant/course/goods/log/list'
    return $axios.fGet(url, data)
}