import $axios from '@/utils/request'


/**
 * 获取学员列表
 * @param {*} data 
 * @returns 
 */
export function getStudentList(data) {
    const url = '/merchant/user/listStudent'
    return $axios.fPost(url, data)
}
/**
 * 添加
 * @param {*} data 
 * @returns 
 */
export function addStudent(data) {
    const url = '/merchant/user/addStudent'
    return $axios.fPost(url, data)
}
/**
 * 编辑
 * @param {*} data 
 * @returns 
 */
export function editStudent(data) {
    const url = '/merchant/user/editStudent'
    return $axios.fPost(url, data)
}
/**
 * 删除
 * @param {*} data 
 * @returns 
 */
export function delStudent(data) {
    const url = '/merchant/user/del'
    return $axios.fPost(url, data)
}

/**
 * 设置状态
 * @param {*} data 
 * @returns 
 */
export function setEnable(data) {
    const url = '/merchant/user/setEnable'
    return $axios.fPost(url, data)
}