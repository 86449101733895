<template>
  <div style="height:calc(100vh - 40px);overflow-y:auto">
      <CourseStatisticsTime
        @setOptions="setOptions"
        @getLog="getLog"
        ref="time"
      />
    <CourseTable :id="id" :select="select" ref="table" />
  </div>
</template>
<script>
import CourseStatisticsTime from "./components/CourseStatisticsTime";
import CourseTable from "./components/CourseTable";
export default {
  components: { CourseStatisticsTime, CourseTable },
  data() {
    return {
      id: "",
      select: [],
    };
  },
  methods: {
    /**
     * 获取明细
     */
    getLog(id,item) {
      this.$nextTick(() => {
        this.$refs.table.drawer = true;
      });
      console.log(id,'-----id')
      this.$refs.table.pageInfo.pageNum = 1
      this.$refs.table.currentId = id
      this.$refs.table.searchForm.courseGoodsId = id
      this.$refs.table.getListHandler(id)
    },
    /**
     * 设置系列下拉
     */
    setOptions(val) {
      this.select = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
  background-color: #ffffff;
}
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
